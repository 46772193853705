.client-logo {
  ul {
    margin: 0 -10px;
    li {
      display: inline-block;
      float: left;
      padding: 0 10px;
      width: 33.33%;
      margin-bottom: 20px;
    }
  }
  padding: 0;
  img {
    width: 100%;
  }
}

.bg-black-1 {
  background-color: #2E1E13;
}
