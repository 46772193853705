@import "style/template/bg";
@import "style/template/about-us";
@import "style/template/our-project";
@import "style/template/w3-team";
@import "style/template/logo";
@import "style/template/blog";
@import "style/template/team";
@import "style/template/login";
@import "style/template/gallery";
@import "style/template/gradient";
@import "style/template/heading-tag";
@import "style/template/contact-form";
@import "style/template/index-new";
@import "style/template/services";
@import "style/template/spa-core";
@import "style/template/timeline";
@import "style/template/spa-experience";
@import "style/template/footer";
@import "style/template/particle";
@import "style/template/responsive";
@import "style/template/home-2";
@import "style/template/about-us-2";
@import "style/template/team-2";
@import "style/template/experts";
@import "style/template/gift-cards";
@import "style/template/pricing";
@import "style/template/latest-blog";
@import "style/template/appointment";
@import "style/template/footer-2";