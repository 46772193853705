.text-white {
  color: #fff;
}

.text-black {
  color: #000;
}

.text-gray {
  color: #f5f6f6;
}

.text-gray-dark {
  color: #d3d3d3;
}

.text-red {
  color: #FF4045;
}

.text-yellow {
  color: #ffb822;
}

.text-pink {
  color: #ff3c85;
}

.text-blue {
  color: #00c5dc;
}

.text-green {
  color: #34bfa3;
}

.text-orange {
  color: #ef9800;
}

/*=== >background color ===*/

.bg-transparent {
  background-color: transparent;
}

.bg-primary {
  background-color: #fff;
}

.bg-white {
  background-color: #FFF;
}

.bg-black {
  background-color: #000;
}

.bg-black-light {
  background-color: #1C1C1C;
}

.bg-gray {
  background-color: #f5f6f6;
}

.bg-gray-dark {
  background-color: #d3d3d3;
}

.bg-red {
  background-color: #FF4045;
}

.bg-green {
  background-color: #34bfa3;
}

.bg-yellow {
  background-color: #ffb822;
}

.bg-pink {
  background-color: #ff3c85;
}

.bg-blue {
  background-color: #00c5dc;
}

.bg-orange {
  background-color: #ef9800;
}

.bg-img-fix {
  background-attachment: fixed;
}