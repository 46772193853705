
/* Appoinment */

.appoinment-1 .appoinment {
  .btn.dropdown-toggle.btn-default, .form-control {
    border-width: 0 0 1px 0 !important;
    border-radius: 0;
    padding: 10px 0;
  }
  label {
    color: #c2c2c2;
  }
  box-shadow: 0 0 15px 5px #dfdfdf;
  margin-bottom: 50px;
  .form-group {
    margin: 25px 0;
  }
}