@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

@mixin three-d-text() {
  text-shadow:  0 1px 0 #999,
  0 2px 0 #888,
  0 3px 0 #777,
  0 4px 0 #666,
  0 5px 0 #555,
  0 6px 0 #444,
  0 7px 0 #333,
  0 8px 7px #001135;
}

@mixin text-hyphens() {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

@mixin three-d($color) {
  color: $color;
  text-shadow: 0 1px 0 #ccc,
  0 2px 0 #c9c9c9,
  0 3px 0 #bbb,
  0 4px 0 #b9b9b9,
  0 5px 0 #aaa,
  0 6px 1px rgba(0,0,0,.1),
  0 0 5px rgba(0,0,0,.1),
  0 1px 3px rgba(0,0,0,.3),
  0 3px 5px rgba(0,0,0,.2),
  0 5px 10px rgba(0,0,0,.25),
  0 10px 10px rgba(0,0,0,.2),
  0 20px 20px rgba(0,0,0,.15);
}

@mixin emboss ($color, $depth) {
  font-weight: bold;
  color: $color;

  text-shadow: 0 ($depth * 0.01em) ($depth * 0.01em) lighten($color, 15%),
  0 ($depth * 0.01em) ($depth * 0.00em) darken($color, 10%),
  0 ($depth * 0.02em) ($depth * 0.00em) darken($color, 11%),
  0 ($depth * 0.03em) ($depth * 0.01em) darken($color, 12%),
  0 ($depth * 0.04em) ($depth * 0.02em) darken($color, 13%),
  0 ($depth * 0.05em) ($depth * 0.03em) darken($color, 14%),
  0 ($depth * 0.06em) ($depth * 0.03em) darken($color, 15%),
  0 ($depth * 0.07em) ($depth * 0.02em) darken($color, 16%),
  0 ($depth * 0.08em) ($depth * 0.01em) darken($color, 17%),
  0 ($depth * 0.09em) ($depth * 0.00em) darken($color, 18%),
  0 ($depth * 0.10em) ($depth * 0.00em) darken($color, 19%),
  0 ($depth * 0.10em) ($depth * 0.04em) rgba(0, 0, 0, 0.2),
  0 ($depth * 0.10em) ($depth * 0.08em) rgba(0, 0, 0, 0.4),
  0 ($depth * 0.10em) ($depth * 0.12em) rgba(0, 0, 0, 0.6),
  0 ($depth * 0.14em) ($depth * 0.10em) rgba(0, 0, 0, 0.2),
  0 ($depth * 0.18em) ($depth * 0.10em) rgba(0, 0, 0, 0.2);
}

@function set-notification-text-color($color) {
  @if (lightness($color) > 50) {
    @return #000000;
  } @else {
    @return #ffffff;
  }
}