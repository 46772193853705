@mixin alpha($opacity) {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity})"; // IE8
  filter: alpha(opacity=#{$opacity});
  opacity: #{$opacity}/100;
}

@mixin blur-effect($default:5) {
  -webkit-filter: blur($default+px);
  -moz-filter: blur($default+px);
  -o-filter: blur($default+px);
  -ms-filter: blur($default+px);
  filter: blur($default+px);
}