@import "header/sm-menu/sm-core-css";

/*---------------------------------------------------------------
14. HEADER (top part of page)
---------------------------------------------------------------*/

.site-header {
  position: relative;
  z-index: 999;
  ul, ol {
    margin-bottom: 0;
  }
}

/*without top bar*/

.extra-nav {
  float: right;
  padding: 26px 0;
  position: relative;
  z-index: 9;
  .site-button-link {
    font-size: 15px;
  }
  .extra-cell {
    display: inline-block;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .extra-nav {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 480px) {
  .extra-nav {
    margin: 0 1px 0 0;
    text-align: right;
  }
}

/* map page header*/

#header-part.fix-map-header {
  height: 90px;
  .main-bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

@media only screen and (max-width: 480px) {
  #header-part.fix-map-header {
    height: 160px;
  }
}

/*---------------------------------------------------------------
15. PAGE TOP BAR (left & right content)
---------------------------------------------------------------*/

.top-bar {
  background-color: #ffffff;
  border-bottom: $topbar-border;
  color: $topbar-color;
  padding: 11px 0;
  font-size: 1.4rem;
}

.dez-topbar-left {
  float: left;
}

.dez-topbar-right {
  float: right;
}

.dez-topbar-center, .dez-topbar-left, .dez-topbar-right {
  padding-left: 15px;
  padding-right: 15px;
}

.dez-topbar-left ul, .dez-topbar-right ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dez-topbar-left ul li, .dez-topbar-right ul li {
  display: inline-block;
}

.dez-topbar-left ul li {
  padding-right: 15px;
  a {
    color: #444444;
  }
}

.dez-topbar-right ul li a {
  color: #444444;
}

.topbar-social li a {
  padding: 0 6px;
}

@media only screen and (max-width: 767px) {
  .top-bar [class*="col-"] {
    width: 100%;
    text-align: right;
    padding: 10px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    &:last-child {
      border-bottom: none;
    }
  }
  .dez-topbar-center, .dez-topbar-left, .dez-topbar-right {
    width: 100%;
    text-align: center;
  }
  .dez-topbar-left ul li {
    padding: 0 5px;
  }
}

/*=== >eamil-phone (left) ===*/

.e-p-bx li {
  display: inline;
  font-size: 14px;
  padding-right: 10px;
  a {
    color: #FFF;
  }
  i {
    margin-right: 10px;
    vertical-align: middle;
  }
}

/*=== >social-links (right) ===*/

.social-bx, .login-bx {
  margin: 0 0 0 15px;
  float: right;
}

.social-bx li, .login-bx li {
  font-size: 12px;
  margin: 0;
  position: relative;
}

.social-bx li {
  padding-left: 1px;
  padding-right: 1px;
  a {
    color: #ffffff;
  }
}

.login-bx li a {
  color: #ffffff;
}

.social-bx li i, .login-bx li i {
  vertical-align: baseline;
  margin: 0 5px 0 0;
}

.social-bx li span {
  vertical-align: baseline;
  margin: 0 2px 0;
  font-size: 8px;
}

.login-bx li {
  span {
    vertical-align: baseline;
    margin: 0 2px 0;
    font-size: 8px;
  }
  ul {
    top: 25px;
    left: auto;
    right: 0;
    border: none;
    padding: 0;
    li {
      border-bottom: 1px solid #F7F7F7;
      a {
        color: #767676;
        padding: 9px 15px;
      }
    }
  }
}

.arrow-up-border:before, .arrow-up:after {
  position: absolute;
  display: inline-block;
  content: '';
}

.arrow-up-border:before {
  top: -7px;
  right: 19px;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #F00;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
}

.arrow-up:after {
  top: -6px;
  right: 20px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
}

.arrow-left {
  &:before {
    position: absolute;
    top: -7px;
    left: 9px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
  }
  &:after {
    position: absolute;
    top: -6px;
    left: 10px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    content: '';
  }
}

/*=== >language-list () ===*/

.language-bx li {
  display: inline;
  margin: 0 10px;
}

/*---------------------------------------------------------------
16. LOGO ( company identify  symbol )
---------------------------------------------------------------*/
/*=== >logo for header ===*/

.logo-header {
  display: table;
  float: left;
  vertical-align: middle;
  padding: 0;
  color: $default-theme-color;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  width: 200px;
  height: 80px;
  position: relative;
  z-index: 9;
  transition: all 0.5s;
}

.logo-footer {
  display: table;
  vertical-align: middle;
  padding: 0;
  color: $default-theme-color;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: 60px;
}

.logo-header > a, .logo-footer > a {
  display: table-cell;
  vertical-align: middle;
}

.logo-header {
  &.center-block {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  img {
    max-width: 100%;
    width: auto;
    height: auto;
  }
}

@media (min-width: 992px) {
  header:not(.header-transparent) {
    .sticky-header {
      @include box-shadow(0,0,30px,rgba(0,0,0,0.1));
    }
    .sticky-header:not(.is-fixed) {
      .logo-header {
        &.page {
          &:before {
            content: " ";
            background-color: white;
            width: 100%;
            height: 150px;
            top: 0;
            position: absolute;
            left: 0;
            z-index: 0;
            border-radius: 20px;
          }
          img {
            position: absolute;
            top: -40px;
            left:0;
            z-index: 10;
            padding: 20px;
            background-color: white;
            border-radius: 20px;
          }
        }
        &:after {
          content:" ";
          position: absolute;
          width: 100%;
          height: 100px;
          bottom: -70px;
          border-radius: 20px;
          left:0;
          z-index: -1;
          background-color: red;
          @include box-shadow(0,0,30px,rgba(0,0,0,0.1));
        }
      }
    }
  }
}

.logo-footer img {
  max-width: 100%;
  width: auto;
  height: auto;
}

.logo-header span, .logo-footer span {
  font-size: 20px;
  color: $default-theme-color;
  letter-spacing: 20px;
}

/*= When header is sticky =*/

.is-fixed {
  .logo-header, &.header-curve .logo-header {
    height: 70px;
    width: 200px;
    transition: all 0.5s;
  }
}

/*= Set width for mobile  =*/

@media only screen and (max-width: 767px) {
  .logo-header {
    width: 200px;
  }
  .is-fixed {
    &.header-curve .logo-header {
      width: 140px;
    }
    .logo-header a img, &.header-curve .logo-header a img {
      vertical-align: sub;
    }
  }
}

/*---------------------------------------------------------------
17. MAIN BAR ( place logo & main-navigation )
---------------------------------------------------------------*/

.main-bar {
  background: #FFFFFF;
  width: 100%;
  position: relative;
}

.header-curve .logo-header {
  &:before, &:after {
    background-color: #fff;
    content: "";
    position: absolute;
    bottom: 0;
    height: 100%;
    z-index: -1;
  }
  &:before {
    right: -50px;
    width: 80%;
    -webkit-transform: skew(30deg);
    -moz-transform: skew(30deg);
    -o-transform: skew(30deg);
    -ms-transform: skew(30deg);
    transform: skew(30deg);
  }
  &:after {
    right: 10%;
    width: 2000px;
  }
}

.is-fixed.header-curve .logo-header img {
  margin-top: -0;
}

@media only screen and (max-width: 767px) {
  .header-curve .logo-header:after {
    right: 0;
    left: auto;
  }
}

/*---------------------------------------------------------------
18. NAVIGATIONS
	>mena-menu
	>mobile-menu
	>footer-menu
---------------------------------------------------------------*/
/*== >mena-menu ==*/

.navbar-toggle {
  border: 0 solid $default-theme-color;
  font-size: 16px;
  line-height: 24px;
  margin: 28px 0 26px 10px;
  padding: 0;
  span {
    background: #666;
  }
}

.is-fixed .navbar-toggle {
  margin: 24px 0 20px 10px;
}

.header-nav {
  position: relative;
  padding: 0;
  .nav {
    float: right;
    i {
      font-size: 9px;
      margin-left: 3px;
      margin-top: -3px;
      vertical-align: middle;
      opacity: 0.7;
    }
    > li {
      margin: 0;
      position: relative;
      > a {
        border-radius: 0;
        color: $menu-color;
        font-size: 15px;
        padding: 30px 18px;
        cursor: pointer;
        &:hover, &.current {
          background-color: transparent;
          color: $default-theme-color;
        }
        &:active, &:focus {
          background-color: transparent;
        }
      }
      &.active > a, &.current-menu-item > a {
        background-color: transparent;
        color: $default-theme-color;
      }
      &:hover > {
        a {
          color: $default-theme-color;
        }
        .sub-menu, .mega-menu {
          opacity: 1;
          visibility: visible;
          margin-top: 0;
          -webkit-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          transition: all 0.5s ease;
        }
      }
      .sub-menu {
        background-color: #ffffff;
        border: 1px solid #f4f4f4;
        display: block;
        left: 0;
        list-style: none;
        margin: 20px 0 0;
        opacity: 0;
        padding: 10px 0;
        position: absolute;
        visibility: hidden;
        width: 220px;
        z-index: 10;
        border-radius: 15px;
        @include box-shadow(0,0,20px,rgba(0,0,0,0.1));
        li {
          border-bottom: 0 solid #f4f4f4;
          position: relative;
          a {
            color: #444444;
            display: block;
            font-size: 1.25rem;
            padding: 5px 20px;
            transition: all 0.15s linear;
            font-weight: 400;
            &:hover {
              background-color: #F2F2F2;
              color: $default-theme-color;
              text-decoration: none;
            }
          }
          &:hover > a {
            color: $default-theme-color;
          }
          &:last-child {
            border-bottom: 0;
          }
          .fa {
            color: inherit;
            display: block;
            float: right;
            font-size: 15px;
            position: absolute;
            right: 15px;
            top: 8px;
            opacity: 1;
            &.fa-nav {
              color: inherit;
              display: inline-block;
              float: none;
              font-size: 13px;
              margin-right: 5px;
              opacity: 1;
              position: unset;
              right: 10px;
              top: 8px;
            }
          }
          > .sub-menu {
            left: 220px;
            margin: 0 0 0 20px;
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -ms-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            transition: all 0.5s ease;
          }
          &:hover > .sub-menu {
            left: 220px;
            margin: 0;
            opacity: 1;
            top: -1px;
            visibility: visible;
            &:before {
              background-color: transparent;
              bottom: 0;
              content: '';
              display: block;
              height: 100%;
              left: -6px;
              position: absolute;
              top: 0;
              width: 6px;
            }
          }
        }
      }
      &.has-mega-menu {
        position: inherit;
      }
      .mega-menu {
        background-color: #ffffff;
        border: 1px solid #e9e9e9;
        display: table;
        left: 0;
        list-style: none;
        opacity: 0;
        position: absolute;
        right: 0;
        visibility: hidden;
        width: 100%;
        font-family: Montserrat;
        margin-top: 20px;
        z-index: 9;
        > li {
          display: table-cell;
          padding: 10px 0;
          position: relative;
          vertical-align: top;
          width: 25%;
          &:after {
            content: "";
            background-color: rgba(204, 204, 204, 0.4);
            position: absolute;
            right: 0;
            top: 0;
            display: block;
            width: 0;
            height: 100%;
          }
          &:last-child:after {
            display: none;
          }
          > a {
            color: #555555;
            display: block;
            font-size: 14px;
            padding: 0 20px;
          }
          ul {
            list-style: none;
            margin: 10px 0px 0px 0;
            padding: 0;
            width: 100%;
            a {
              color: #777777;
              display: block;
              font-size: 14px;
              line-height: 34px;
              text-transform: capitalize;
              padding: 0 20px;
              font-weight: 400;
              &:hover {
                color: $default-theme-color;
                background-color: #F2F2F2;
              }
            }
          }
        }
      }
    }
    .mega-menu a i {
      color: #505050;
      font-size: 15px;
      margin-right: 5px;
      opacity: 1;
      text-align: center;
      width: 15px;
    }
    > li.menu-item-has-children:before {
      content: "\f078";
      display: block;
      font-family: "FontAwesome";
      right: 4px;
      position: absolute;
      top: 50%;
      color: #999;
      margin-top: -8px;
      font-size: 8px;
    }
  }
}

/* Header Center */

.site-header {
  .b {}
  &.center {
    .header-nav .nav {
      display: inline-block;
      float: left;
      width: 35%;
    }
    .navbar-nav > li {
      display: inline-block;
      float: none;
    }
    .header-nav .nav {
      &:first-child {
        text-align: right;
        li {
          text-align: left;
        }
      }
      &:last-child {
        float: right;
      }
    }
    .logo-header {
      position: absolute;
      transform: translate(-50%);
      position: absolute;
      left: 50%;
      text-align: center;
      width: 140px;
      img {
        height: 100px;
        margin-bottom: -50px;
        position: relative;
        width: 100px;
        z-index: 2;
        object-fit: contain;
      }
      &:after {
        background-image: url("../images/header/header.png");
        background-position: center top;
        background-repeat: no-repeat;
        background-size: 100% auto;
        content: "";
        height: 100px;
        position: absolute;
        width: 140%;
        bottom: -99px;
        left: -20%;
      }
    }
  }
}

/* Mega Menu */

.header-nav .nav > li {
  &.has-mega-menu.demos .mega-menu {
    left: auto;
    right: auto;
    max-width: 600px;
    z-index: 9;
  }
  .sub-menu li {
    > .sub-menu.left, &:hover .sub-menu.left {
      left: auto;
      right: 220px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .site-header.center {
    .header-nav .nav {
      width: 100%;
    }
    .navbar-nav > li {
      display: block;
      float: left;
      width: 100%;
    }
  }
  .header-nav .nav > li {
    &.has-mega-menu.demos .mega-menu {
      max-width: 100%;
    }
    .mega-menu {
      border: 0;
    }
  }
  .site-header.center .is-fixed .header-nav .nav {
    height: auto;
  }
}

/* Nav  Badge */

.shop-cart {
  &.navbar-right {
    margin-right: 0;
    float: none;
    display: inline-block;
  }
  .dropdown-menu.cart-list {
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);
    top: 50px;
    border: 0;
  }
  .badge {
    font-size: 9px;
    height: 15px;
    line-height: 15px;
    min-width: 15px;
    padding: 0;
    position: absolute;
    right: -5px;
    text-align: center;
    top: -5px;
  }
  li {
    padding: 10px 20px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
    &:last-child {
      border-bottom: 0;
    }
  }
  .media-left a {
    display: block;
    width: 60px;
  }
  .site-button-link {
    position: relative;
  }
}

.cart-list.dropdown-menu {
  width: 260px;
  margin-top: 30px;
}

.is-fixed .cart-list.dropdown-menu {
  margin-top: 18px;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.cart-list .media-object {
  width: 60px;
}

.cart-item .media-body {
  position: relative;
}

.item-close {
  background-color: #303030;
  border-radius: 20px;
  color: #ffffff;
  display: block;
  font-size: 9px;
  height: 18px;
  line-height: 18px;
  margin-top: -9px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  width: 18px;
  cursor: pointer;
  &:hover {
    background-color: #000;
  }
}

@media only screen and (max-width: 991px) {
  header .logo {
    display: block;
    padding: 4px 0;
  }
  .header-nav .nav {
    > li > a {}
    i {
      margin-top: 6px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .shop-cart .dropdown-menu.cart-list {
    left: auto;
    right: 0;
  }
}

@media only screen and (max-width: 991px) {
  /* responsive strat when media screen [991px] css START*/
  .navbar-header {
    float: none;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse.collapse {
    display: none !important;
    &.in {
      /* NEW */
      display: block !important;
    }
  }
  /* responsive strat when medai screen [991px] css END*/
  .header-nav {
    clear: both;
    margin: 0 -15px;
    border-bottom: 1px solid #E9E9E9;
    .nav {
      float: none;
      margin: 0;
      background: #fff;
      li {
        float: none;
      }
      > li {
        .sub-menu > li, .mega-menu > li {
          float: none;
          display: block;
          width: auto;
        }
        > a {
          padding: 10px 15px;
          //border-top: 1px solid #E9E9E9;
        }
      }
      i {
        float: right;
      }
      > li {
        > a {
          &:hover, &:active, &:focus {
            background-color: #f0f0f0;
            text-decoration: none;
          }
        }
        .mega-menu > li:after {
          display: none;
        }
        ul, .sub-menu, .mega-menu {
          display: none;
          position: static;
          visibility: visible;
          width: auto;
          background: #f9f9f9;
        }
        ul.mega-menu ul {
          display: none;
        }
        &:hover {
          > ul, .sub-menu, .mega-menu {
            opacity: 1;
            visibility: visible;
            display: block;
            margin: 0;
          }
        }
        .sub-menu li > .sub-menu {
          opacity: 1;
          visibility: visible;
          display: block;
          margin: 0;
        }
        ul.mega-menu li:hover ul {
          display: block;
        }
      }
    }
  }
}

/*= nav dark version =*/

.nav-dark.header-nav .nav > li {
  .sub-menu, .mega-menu {
    background-color: #252525;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .sub-menu li {
    border-bottom: 0px solid rgba(0, 0, 0, 0.2);
    a {
      color: #9d9d9d;
    }
  }
  .mega-menu > li {
    ul a, > a {
      color: #9d9d9d;
    }
  }
  .sub-menu li {
    a:hover {
      background-color: rgba(255, 255, 255, 0);
    }
    &:hover > a {
      background-color: rgba(255, 255, 255, 0);
      color: #fff !important;
    }
  }
  ul li a:hover {
    background-color: rgba(255, 255, 255, 0);
    color: #fff !important;
  }
  .mega-menu > li {
    ul a:hover {
      background-color: rgba(255, 255, 255, 0);
      color: #fff !important;
    }
    &:after {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

@media only screen and (max-width: 991px) {
  .nav-dark.header-nav {
    .nav {
      > li > a {
        color: #9d9d9d;
        border-top: 0px solid rgba(0, 0, 0, 0.2);
      }
      background-color: #202020;
    }
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);
  }
  [class*="bg-"] .nav-dark.header-nav .nav > li {
    > a {
      color: #999;
    }
    &.active > a, &:hover > a {
      color: $default-theme-color;
    }
  }
  .nav-dark.header-nav .nav > li {
    > a {
      &:hover, &:active, &:focus {
        background-color: #282828;
        color: $default-theme-color;
      }
    }
    ul, .sub-menu, .mega-menu {
      background-color: #282828;
    }
  }
}

/*= when header is sticky =*/

.sticky-no .main-bar {
  position: static !important;
}

/*= when header is sticky =*/

.is-fixed {
  .main-bar {
    position: fixed;
    top: 0;
    left: 0;
    @include box-shadow(0,0,40px,rgba(0,0,0,.1));
  }
  //.header-nav .nav > li > a {
  //  padding: 25px 18px;
  //}
  .extra-nav {
    padding: 21px 0;
  }
}

@media only screen and (max-width: 991px) {
  .is-fixed .header-nav .nav > li > a {
    padding: 12px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .is-fixed {
    .header-nav .nav {
      overflow: auto;
      height: 225px;
      > li > a {
        padding: 12px 15px;
      }
    }
    &#header-part .navbar-toggle {
      margin: 15px 0;
    }
  }
}

/*= header full width =*/

#header-part.full-width {
  .top-bar .container, .main-bar .container {
    width: auto;
  }
}

/*= Header style designs strat =*/
/*header with bg primary color 1 */

[class*="bg-"] {
  .header-nav .nav > li {
    > a, &.active > a, &.current-menu-item > a, > a:hover, &:hover > a {
      color: #fff;
    }
  }
  .navbar-toggle {
    border-color: #fff;
    span {
      background-color: #fff;
    }
  }
}

@media only screen and (max-width: 991px) {
  [class*="bg-"] .header-nav .nav > li > a {
    color: #555;
  }
}

/*header style 1 (default) */
/* Header Dark */

.header.dark {
  background: #252525;
  .main-bar {
    background: #252525;
  }
  .header-nav .nav > li > a, .extra-nav .site-button-link, .navbar-toggle {
    color: #dfdfdf;
  }
  .top-bar {
    background-color: #252525;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    color: $default-txt-color;
  }
}

/* Header Transparent  */

.header-transparent {
  position: absolute;
  width: 100%;
  .main-bar {
    background-color: rgba(0, 0, 0, 0);
  }
  .header-nav .nav > li > a {
    color: #fff;
  }
  .extra-nav .site-button-link, .navbar-toggle {
    color: #fff;
  }
  &.tb {
    .header-nav .nav > li > a, .top-bar, .header-nav .nav > li > a, .extra-nav .site-button-link, .navbar-toggle {
      color: $menu-page-color;
    }
  }
  .is-fixed {
    .main-bar {
      position: fixed;
      background-color: #fff;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
    }
    .header-nav .nav > li > a, .extra-nav .site-button-link, .navbar-toggle {
      color: #000;
    }
  }
  .top-bar {
    background-color: rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    color: $default-txt-color;
  }
  &.overlay {
    .top-bar, .main-bar {
      background-color: rgba(0, 0, 0, 0.5);
    }
    .header-nav .nav > li > a, .extra-nav .site-button-link, .navbar-toggle {
      color: #fff;
    }
  }
}

/* Text Whitw */

/* Overlay */

/* Header Full Width */

.fullwidth .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (max-width: 991px) {
  .header-transparent.overlay .header-nav .nav > li > a {
    color: #000;
  }
}

@media only screen and (max-width: 767px) {
  .fullwidth .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* Colored Bg */
/*=== Primary */

.header {
  &.primary {
    background: #3B68E7;
    .main-bar {
      background: #3B68E7;
    }
    .header-nav .nav > li > a, .extra-nav .site-button-link, .navbar-toggle {
      color: #dfdfdf;
    }
    .top-bar {
      background-color: #3B68E7;
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
      color: $default-txt-color;
    }
  }
  &.red {
    background: #F22D4E;
    .main-bar {
      background: #F22D4E;
    }
    .header-nav .nav > li > a, .extra-nav .site-button-link, .navbar-toggle {
      color: #dfdfdf;
    }
    .top-bar {
      background-color: #F22D4E;
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
      color: $default-txt-color;
    }
  }
  &.blue {
    background: #00c5dc;
    .main-bar {
      background: #00c5dc;
    }
    .header-nav .nav > li > a, .extra-nav .site-button-link, .navbar-toggle {
      color: #fff;
    }
    .top-bar {
      background-color: #00c5dc;
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
      color: #fff;
    }
  }
  &.purpal {
    background: #8862b9;
    .main-bar {
      background: #8862b9;
    }
    .header-nav .nav > li > a, .extra-nav .site-button-link, .navbar-toggle {
      color: #fff;
    }
    .top-bar {
      background-color: #8862b9;
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
      color: #fff;
    }
  }
  &.yellow {
    background: #ffb822;
    .main-bar {
      background: #ffb822;
    }
    .header-nav .nav > li > a, .extra-nav .site-button-link, .navbar-toggle {
      color: #fff;
    }
    .top-bar {
      background-color: #ffb822;
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
      color: #fff;
    }
  }
}

/*=== Red */

/*=== Blue */

/*=== Purpal */

/*=== Yellow */

/* Top Bar Bg */

.top-bar {
  &.bg-dark {
    background-color: #282a3c;
    color: #fff;
  }
  &.bg-black {
    background-color: #000;
    color: #fff;
  }
  &.bg-light {
    background-color: #a5a5a5;
    color: #ffffff;
    border-bottom: 0;
  }
}

/* Box */

.header.box {
  position: absolute;
  width: 100%;
  .main-bar {
    background-color: rgba(0, 0, 0, 0);
    margin-top: 20px;
    transition: all 0.2s;
  }
  .header-nav .nav > li > a, .extra-nav .site-button-link, .navbar-toggle {
    color: #000;
  }
  .is-fixed .main-bar {
    position: fixed;
    background-color: #fff;
    margin-top: 0;
    transition: all 0.2s;
  }
  .container {
    background-color: #ffffff;
    border-radius: 2px;
    box-shadow: 0 0 4px -2px #000000;
  }
  .is-fixed .container {
    box-shadow: none;
  }
  &.overlay {
    .header-nav .nav > li > a, .extra-nav .site-button-link, .navbar-toggle {
      color: #fff;
    }
    .container {
      background-color: rgba(0, 0, 0, 0.5);
    }
    .is-fixed {
      .container {
        background-color: rgba(0, 0, 0, 0);
      }
      .main-bar {
        background-color: rgba(0, 0, 0, 0.7);
      }
    }
  }
  &.dark {
    .header-nav .nav > li > a, .extra-nav .site-button-link, .navbar-toggle {
      color: #fff;
    }
    .container {
      background-color: #252525;
    }
    .is-fixed {
      .container {
        background-color: rgba(0, 0, 0, 0);
      }
      .main-bar {
        background-color: #252525;
      }
    }
    background-color: rgba(0, 0, 0, 0);
  }
}

/* Box Overlay */

/* Box Dark */

/* One Page Nav */

.onepage .navbar {
  margin-bottom: 0;
  border: 0;
}

@media only screen and (max-width: 991px) {
  .header.dark .header-nav .nav > li > a, .header-transparent .header-nav .nav > li > a {
    color: #505050;
  }
  .header {
    &.primary .header-nav .nav > li > a, &.blue .header-nav .nav > li > a, &.purpal .header-nav .nav > li > a, &.red .header-nav .nav > li > a, &.yellow .header-nav .nav > li > a {
      color: #505050;
    }
    &.box {
      &.dark .header-nav .nav > li > a, &.overlay .header-nav .nav > li > a {
        color: #505050;
      }
    }
  }
  .header-curve [class*="bg-"] .header-nav .nav > li.active > a {
    color: #505050;
  }
}