

/*=== About Us ===*/
.about-us {
  min-height: 700px;
  .dez-tabs {
    .nav-tabs {
      border: 0 none;
      left: 61px;
      position: absolute;
      top: 0;
      -o-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      -o-transform-origin: left top 0;
      -webkit-transform-origin: left top 0;
      -moz-transform-origin: left top 0;
      transform-origin: left top 0;
      width: auto;
      li {
        display: inline-block;
        margin: 0;
        background-color: #f6f6f6;
        text-align: center;
        float: left;
        border-right: 4px solid #e8e8e8;
      }
    }
    .about-contant {
      padding: 30px 30px 10px 30px;
    }
    position: relative;
    .tab-content {
      margin-left: 60px;
      border: 0;
      h3 {
        margin-top: 0;
      }
    }
    .nav-tabs li {
      a {
        border: 0;
        -o-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
      &.active {}
    }
  }
}

.owl-none .owl-nav {
  display: none;
}

.about-us .dez-tabs .nav-tabs li {
  &.active a {
    border: 0;
    color: #000;
    h4, span {
      color: #000;
    }
  }
  h4 {
    margin: 0;
    text-transform: uppercase;
    color: #757575;
  }
  a span {
    font-weight: 300;
    font-size: 14px;
  }
}
