
/* Spicial Gifts & Cards */

.special-gifts {
  .gifts-price {
    color: #7e7e7e;
    font-size: 20px;
    font-weight: 500;
  }
  .gifts {
    .dez-title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 0;
    }
    &.bg-gray {
      background-color: #F8F9FD;
    }
    .dez-divider {
      margin-bottom: 0;
    }
  }
  .dez-box {
    border: 0 none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }
}

.bg-gray-primary {
  background-color: #faf9fd;
}