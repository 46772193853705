
/*---------------------------------------------------------------
21. BLOG
---------------------------------------------------------------*/

.blog-post {
  position: relative;
  margin-bottom: 30px;
}

.dez-post-info {}

.dez-post-title {
  margin-bottom: 10px;
  .post-title {
    margin-top: 15px;
  }
}

.dez-post-meta {
  margin-bottom: 10px;
  ul {
    margin: 0 -4px;
    list-style: none;
    li {
      padding: 0;
      display: inline-block;
    }
  }
  li {
    &:after {
      content: "/";
      display: inline-block;
      font-weight: normal;
      margin-left: 5px;
      opacity: 0.5;
    }
    &:last-child:after {
      display: none;
    }
  }
  a {
    color: #a9a9a9;
  }
  i {
    color: #7b7b7b;
    margin: 0 5px;
  }
}

.dez-post-text {
  margin-bottom: 15px;
  p:last-child {
    margin: 0;
  }
}

.dez-post-readmore {
  margin-bottom: 15px;
}

.dez-post-tags {
  border-top: 1px solid #E9E9E9;
  padding-top: 10px;
  .post-tags {
    margin: 0 -3px;
    list-style: none;
    a {
      border: 1px solid #ebedf2;
      padding: 2px 8px;
      color: #a9a9a9;
      margin: 0 3px;
      display: inline-block;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 600;
    }
  }
}

/*== post date syle css ==*/

.date-style-1 .post-date {
  color: #fff;
  background-color: $default-theme-color;
  padding: 3px 5px 3px 5px;
  .fa {
    color: #fff;
    left: 5px;
    top: 5px;
  }
}

.date-style-2 .post-date, .date-style-4 .post-date {
  color: #fff;
  position: absolute;
  left: 20px;
  top: 0;
  width: 50px;
  text-align: center;
  text-transform: uppercase;
}

.date-style-2 .post-date {
  strong, span {
    display: block;
    padding: 10px 5px;
  }
}

.date-style-4 .post-date {
  strong, span {
    display: block;
    padding: 10px 5px;
  }
}

.date-style-2 .post-date strong, .date-style-4 .post-date strong {
  font-size: 10px;
  padding: 10px 5px 5px;
  position: relative;
}

.date-style-2 .post-date {
  > strong:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 1px;
    background-color: #fff;
    margin: 0 -10px;
    width: 20px;
  }
  span {
    font-size: 16px;
    padding: 5px 5px 10px;
  }
  &:after, .fa {
    display: none;
  }
}

.date-style-3 .post-date {
  &:after, .fa {
    display: none;
  }
}

.date-style-4 .post-date .fa {
  display: none;
}

.date-style-3 {
  .dez-post-info {
    position: relative;
  }
  .post-date {
    font-family: oswald;
    text-transform: uppercase;
    position: absolute;
    padding: 8px 15px;
    color: #fff;
    top: -36px;
    left: 0;
    height: 36px;
    strong {
      color: #30353c;
      margin-right: 2px;
    }
  }
  &.skew-date .post-date {
    padding-right: 8px;
    &:before {
      content: "";
      position: absolute;
      right: -15px;
      top: 0;
      width: 22px;
      height: 100%;
      border-right: 3px solid #2D3239;
      -moz-transform: skewX(20deg);
      -webkit-transform: skewX(20deg);
      -o-transform: skewX(20deg);
      -ms-transform: skewX(20deg);
      transform: skewX(20deg);
    }
  }
}

.date-style-4 {
  .post-date {
    span {
      background-color: #2e1e13;
    }
    top: 10px;
    left: 10px;
    font-family: "Roboto Slab", serif;
  }
  .dez-post-info {
    box-shadow: 0 3px 0 0 #dbdbdb;
  }
  .dez-post-meta ul li {
    font-weight: normal;
  }
}

/*== post without media ==*/

.no-image-blog.date-style-2 {
  .algo-post-info {
    padding-top: 70px;
  }
  .post-date {
    top: 0;
    left: 20px;
  }
}

/*if no image with blog post*/

.blog-post.no-img-post .post-date {
  position: static;
  float: left;
}

/*blog post half iamge*/

.blog-md {
  .dez-post-media {
    width: 350px;
    float: left;
    margin-right: 30px;
  }
  .dez-post-info {
    border: none;
  }
  .dez-post-tags {
    border: none;
    display: inline-block;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog-md.blog-post .dez-post-media {
    float: none;
    margin: 0 0 20px;
    width: auto;
  }
}

/*Blog page pagination main*/

.nav-links:after {
  clear: both;
  content: "";
  display: table;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}

.pagination {
  .page-numbers {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    color: #767676;
    padding: 9px 15px;
    font-weight: 600;
    font-size: 12px;
    display: block;
    float: left;
    margin-left: -1px;
    &.current {
      background: #3396D1;
      color: #fff;
    }
  }
  a.page-numbers:hover {
    background: #3396D1;
    color: #fff;
  }
}

/*Blog paging Next & Prev css*/

.nav-links {
  .nav-previous a, .nav-next a {
    display: inline-block;
    color: #3396d1;
    font-weight: 600;
    margin-bottom: 20px;
    outline: none !important;
  }
  .nav-previous a:hover, .nav-next a:hover {
    color: #0099CC;
  }
  .nav-previous a:after, .nav-next a:after {
    content: "\f105";
    font-family: "FontAwesome";
    vertical-align: middle;
    margin-left: 2px;
    color: #333;
  }
}

/*Post navigation*/

.post-navigation {
  background-color: #fff;
  padding: 30px;
  border-bottom: 2px solid #e3e3e3;
  .screen-reader-text {
    margin-top: 0;
  }
  .nav-previous {
    display: inline-block;
    width: 50%;
  }
  .nav-next {
    display: inline-block;
    width: 50%;
    text-align: right;
  }
  .nav-previous a:after, .nav-next a:after {
    display: none;
  }
  .nav-previous .post-title, .nav-next .post-title {
    display: block;
    font-size: 15px;
    color: #777777;
    margin-top: 10px;
  }
}

/*post panging*/

.dez-link-pages {
  padding: 20px 0 30px;
  a {
    color: #fff;
    padding: 2px 6px;
    font-size: 12px;
    font-weight: 600;
    background-color: #CCC;
    margin: 0 2px;
  }
}

/*Sticky Post*/

.sticky-post {
  position: absolute;
  right: 0;
  top: 0;
  background: #77c04b;
  color: #FFF;
  padding: 10px 15px;
  text-transform: uppercase;
  font-weight: 600;
}

/*Post password form*/

.post-password-form {
  position: relative;
  clear: both;
  input[type="password"] {
    width: 100%;
    border: 1px solid #ebedf2;
    padding: 10px 100px 10px 15px;
    height: 40px;
  }
  label {
    display: block;
  }
  input[type="submit"] {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px 20px;
    background-color: #333;
    color: #FFF;
    border: none;
    height: 40px;
    text-transform: uppercase;
    font-weight: 600;
  }
}

/*gallery css for blog post*/

.blog [class*="galleryid-"] {
  clear: both;
  margin: 0 auto;
  overflow: hidden;
}

.gallery {
  .gallery-item {
    float: left;
    list-style: none;
    margin: 0px 0 0;
    overflow: hidden;
    padding: 0px 0px 5px 15px;
    text-align: center;
  }
  .gallery-caption {
    padding-top: 10px;
    font-size: 13px;
  }
  .gallery-item img {
    float: left;
    padding: 0 0;
    width: 100%;
    border: none !important;
  }
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-icon img {
  margin: 0 auto;
}

.gallery-caption {
  color: #707070;
  color: rgba(51, 51, 51, 0.7);
  display: block;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
  padding: 0.5em 0;
}

.gallery-columns-6 .gallery-caption, .gallery-columns-7 .gallery-caption, .gallery-columns-8 .gallery-caption, .gallery-columns-9 .gallery-caption {
  display: none;
}

/*side bar*/

.side-bar .widget {
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .side-bar {
    margin-bottom: 40px;
  }
}

/*Blog single*/

.blog-single {
  .dez-post-meta {
    margin-bottom: 20px;
  }
  .dez-post-text, .dez-post-tags {
    margin-top: 20px;
  }
}

/*= comment list = */

.comments-area {
  padding: 0;
  .comments-title {
    text-transform: uppercase;
    font-size: 20px;
    &:first-letter {
      font-family: "Open Sans";
      font-weight: 600;
      color: $default-theme-color;
    }
  }
}

ol.comment-list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  li {
    &.comment {
      position: relative;
      padding: 0;
      .comment-body {
        position: relative;
        padding: 20px;
        margin-bottom: 40px;
        margin-left: 80px;
        position: relative;
        border: 1px solid #e9e9e9;
        &:before, &:after {
          content: '';
          position: absolute;
          top: 20px;
          width: 0;
          height: 0;
          border-width: 10px 12px 10px 0;
          border-style: solid;
        }
        &:before {
          border-color: transparent #fff transparent #fff;
          z-index: 1;
          left: -12px;
          top: 22px;
        }
        &:after {
          border-color: transparent #e9e9e9 transparent #e9e9e9;
          border-width: 12px 14px 12px 0;
          left: -14px;
        }
      }
      .comment-author {
        display: block;
        margin-bottom: 10px;
        .avatar {
          position: absolute;
          top: 0;
          left: -80px;
          width: 56px;
          height: 56px;
          border-radius: 100%;
          -webkit-border-radius: 100%;
          border: 2px solid #FFF;
        }
        .fn {
          display: inline-block;
          color: #555555;
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 600;
          font-style: normal;
        }
        .says {
          display: none;
          color: #999999;
          font-weight: 600;
        }
      }
      .comment-meta {
        color: #8d8d8d;
        text-transform: uppercase;
        margin-bottom: 15px;
        a {
          color: #8d8d8d;
          color: #8d8d8d;
        }
        &:before {
          font-family: "FontAwesome";
          font-size: 11px;
          vertical-align: top;
        }
      }
      .reply a:before {
        font-family: "FontAwesome";
        font-size: 11px;
        vertical-align: top;
      }
      .comment-meta:before {
        content: "\f133";
      }
      .reply a:before {
        content: "\f064";
        font-weight: normal;
        color: #555555;
        margin-right: 5px;
        vertical-align: middle;
      }
      p {
        line-height: 18px;
        margin: 0 0 5px;
      }
      .reply a {
        position: absolute;
        top: 50px;
        right: 30px;
        margin-top: -5px;
        color: $default-theme-color;
        font-weight: 600;
      }
    }
    .children {
      list-style: none;
      margin-left: 80px;
      li {
        padding: 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .comments-area .padding-30 {
    padding: 15px;
  }
  ol.comment-list li {
    &.comment {
      .comment-body {
        margin-bottom: 30px;
        margin-left: 70px;
      }
      .comment-author .avatar {
        left: -75px;
        height: 60px;
        width: 60px;
      }
    }
    .children {
      margin-left: 20px;
    }
    &.comment .reply a {
      position: static;
    }
  }
}

@media only screen and (max-width: 480px) {
  ol.comment-list li.comment {
    .comment-body {
      margin-left: 52px;
    }
    .comment-author .avatar {
      left: -55px;
      top: 12px;
      width: 40px;
      height: 40px;
    }
  }
}

/*= comment form = */

.comment-respond {
  padding: 30px 30px;
  border: 1px solid #e9e9e9;
  .comment-reply-title {
    text-transform: uppercase;
    font-size: 20px;
    font-size: 16px;
    font-weight: 600;
  }
}

.comments-area .comment-form {
  margin: 0 -15px;
  .comment-notes {
    display: none;
  }
  p {
    width: 33.333%;
    float: left;
    padding: 0 15px;
    margin-bottom: 30px;
    position: relative;
    &.form-allowed-tags {
      width: 100%;
    }
  }
}

ol.comment-list li.comment .comment-respond .comment-form p {
  padding: 0 15px !important;
}

.comments-area {
  .comment-form p label {
    display: none;
    line-height: 18px;
    margin-bottom: 10px;
  }
  p {
    &:before {
      font-family: "FontAwesome";
      display: inline-block;
      position: absolute;
      left: 15px;
      top: 0;
      font-size: 16px;
      color: #ccc;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #e1e6eb;
      text-align: center;
      border-radius: 4px 0 0 4px;
      -webkit-border-radius: 4px 0 0 4px;
    }
    &.comment-form-author:before {
      content: "\f007";
    }
    &.comment-form-email:before {
      content: "\f0e0";
    }
    &.comment-form-url:before {
      content: "\f0ac";
    }
    &.comment-form-comment:before {
      content: "\f040";
    }
  }
  .comment-form p {
    input[type="text"], textarea {
      width: 100%;
      height: 40px;
      line-height: 12px;
      padding: 10px 10px 10px 50px;
      border: 1px solid #e1e6eb;
      border-radius: 4px;
      -webkit-border-radius: 4px;
    }
    &.comment-form-comment {
      width: 100%;
      display: block;
      clear: both;
    }
    textarea {
      height: 120px;
    }
    &.form-submit {
      clear: both;
      float: none;
      width: 100%;
      margin: 0;
    }
    input[type="submit"] {
      background-color: $default-theme-color;
      border: none;
      border-radius: 3px;
      border-style: solid;
      border-width: 1px;
      color: #fff;
      display: inline-block;
      font-weight: 600;
      padding: 10px 20px;
      text-transform: uppercase;
      &:hover, &:focus, &:active {
        background-color: #6ab33e;
        border-color: #6ab33e;
        color: #fff;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .comments-area .comment-form p {
    width: 100%;
    float: none;
    margin-bottom: 20px;
  }
  .comment-respond {
    padding: 20px;
  }
}