
/*---------------------------------------------------------------
7. POSITIONS ( absolute & relative & statice & z-index )
---------------------------------------------------------------*/

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.static {
  position: static;
}

.z-index1 {
  z-index: 1;
}

.z-index2 {
  z-index: 2;
}

.z-index3 {
  z-index: 3;
}

.z-index4 {
  z-index: 4;
}

.z-index5 {
  z-index: 5;
}

.z-index6 {
  z-index: 6;
}

.z-index7 {
  z-index: 7;
}

.z-index8 {
  z-index: 8;
}

.z-index9 {
  z-index: 9;
}

.z-index10 {
  z-index: 10;
}

.z-index100 {
  z-index: 100;
}

.z-index999 {
  z-index: 999;
}