.dis-block {
  display: block;
}

.bg-img-fix {
  background-size: cover;
}

.bg-secondry {
  background-color: #2c4a6b;
}

.text-gray-dark {
  color: #9a9a9a;
}