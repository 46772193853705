
/* Contact Form */

.dez-social-icon.dez-social-icon-lg li {
  a {
    color: #ffffff;
    font-size: 14px;
    height: 35px;
    line-height: 34px;
    padding: 0;
    width: 35px;
  }
  display: inline-block;
  font-size: 12px;
  padding: 0;
}

.dez-social-icon-lg.dez-social-icon.border li a {
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.contact-style-1 {
  input {
    height: 50px;
  }
  .site-button {
    height: 50px;
    width: 100%;
  }
  .form-control {
    padding: 10px 20px;
    &:focus, &:hover {
      border-color: #c0c0c0;
    }
  }
  h2 {
    margin-top: 0;
  }
  .border-1 {
    border: 1px solid #eee;
  }
  .dez-social-icon-lg {
    border-top: 1px solid #eeeeee;
    padding-top: 20px;
    text-align: left;
    width: 100%;
    margin: 0;
    a {
      text-align: center;
    }
    li a:hover {
      background: #fff;
      color: #000;
      border: 1px solid #eee;
    }
  }
  .form-group {
    margin-bottom: 25px;
  }
}

.sort-title {
  margin-bottom: 40px;
  h4:after {
    left: -160px;
  }
  &.text-white h4 {
    &:after, &:before {
      background: #fff;
    }
  }
  h4 {
    &:after, &:before {
      background: #000 none repeat scroll 0 0;
      content: "";
      height: 1px;
      position: absolute;
      right: -160px;
      top: 50%;
      width: 150px;
      opacity: 0.15;
    }
    display: inline-block;
    margin: 0;
    position: relative;
  }
}