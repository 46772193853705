
/* Footer */

.ft-gallery-box {
  border: 1px solid $default-theme-color;
  padding: 20px 30px 50px 20px;
  border-radius: 12px;
  margin-top: -200px;
}

.ft-gallery-media {
  img {
    border-radius: 12px;
    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.35);
  }
  position: relative;
  z-index: 1;
  &:after {
    background: $default-theme-color none repeat scroll 0 0;
    border-radius: 12px;
    bottom: -15px;
    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.35);
    color: #000000;
    content: "";
    height: 70%;
    position: absolute;
    right: -15px;
    width: 40%;
    z-index: -1;
  }
}

.ft-gallery-box {
  .owl-theme .owl-controls {
    bottom: -80px;
    left: 50%;
    position: absolute;
    transform: translate(-50%);
  }
  .item {
    padding: 10px;
  }
}

.spa-footer .footer-bottom {
  font-size: 16px;
  color: #363636;
}

.ft-gallery-media .owl-nav {
  width: 150px;
}

.spa-footer {
  .footer-bottom, .footer-top {
    background-color: $theme-color-light;
  }
  .footer-bottom .container, .footer-top .container {
    position: relative;
    z-index: 2;
  }
  position: relative;
}

.footer-bg {
  background-image: url("../images/background/bg11.jpg");
  background-position: left bottom;
  background-size: 100% auto;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  background-repeat: no-repeat;
}

footer.spa-footer {
  p, li {
    font-size: 16px;
  }
  h1, h2, h3, h4, h5, h6, h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, p, strong, b, h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, p a {
    color: #363636;
  }
  color: #363636;
  a {
    color: #363636;
    &:visited {
      color: #363636;
    }
  }
  p a {
    color: #363636;
  }
  a {
    &:active, &:focus, &:hover {
      color: #363636;
    }
  }
  .widget_categories ul li a, .widget_archive ul li a, .widget_meta ul li a, .widget_pages ul li a, .widget_recent_comments ul li a, .widget_nav_menu li a, .widget_recent_entries ul li a, .widget_services ul li a {
    color: #363636;
  }
}
