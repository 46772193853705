
/*---------------------------------------------------------------
2. HEADINGS
---------------------------------------------------------------*/

h1, h2, h3, h4, h5, h6 {
  color: $default-heading-color;
  font-family: "Nunito",sans-serif;
  line-height: 1.1em;
  letter-spacing: -0.035em;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: $default-heading-color;
}

h1 {
  font-size: 36px;
  margin-bottom: 25px;
}

h2 {
  font-size: 32px;
  margin-bottom: 25px;
}

h3 {
  font-size: 28px;
  margin-bottom: 20px;
}

h4 {
  font-size: 22px;
  margin-bottom: 15px;
}

h5 {
  font-size: 18px;
  margin-bottom: 10px;
}

h6 {
  font-size: 16px;
  margin-bottom: 10px;
}