
/* Particles */

.particles {
  position: relative;
  .container {
    z-index: 2;
    position: relative;
  }
}

.bg-primary {}