/* Mobile first layout SmartMenus Core CSS (it's not recommended editing these rules)
   You need this once per page no matter how many menu trees or different themes you use.
-------------------------------------------------------------------------------------------*/

.sm {
  box-sizing: border-box;
  //position: relative;
  //z-index: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.sm, .sm ul, .sm li {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left;
}

.sm-rtl, .sm-rtl ul, .sm-rtl li {
  direction: rtl;
  text-align: right;
}

.sm > li > h1, .sm > li > h2, .sm > li > h3, .sm > li > h4, .sm > li > h5, .sm > li > h6 {
  margin: 0;
  padding: 0;
}

.sm ul {
  display: none;
}

.sm li, .sm a {
  position: relative;
}

.sm a {
  display: block;
}

.sm a.disabled {
  cursor: default;
}

.sm::after {
  content: "";
  display: block;
  height: 0;
  font:0/0 serif;
  clear: both;
  overflow: hidden;
}

.sm *, .sm *::before, .sm *::after {
  box-sizing: inherit;
}

@import "clean/sm-clean";


@media (min-width: 768px) {
  /* prevent text rendering degrade during animation in Mac browsers */
  #main-menu {
    -webkit-font-smoothing: subpixel-antialiased;
  }
  /* show animation */
  #main-menu ul.show-animation {
    -webkit-animation: show-animation 0.2s;
    animation: show-animation 0.2s;
  }
  @-webkit-keyframes show-animation {
    0% {
      -webkit-transform: scale3d(0, 0, 1);
      opacity: 0;
    }
    60% {
      -webkit-transform: scale3d(1.05, 1.05, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      opacity: 1;
    }
  }
  @keyframes show-animation {
    0% {
      transform: scale3d(0, 0, 1);
      opacity: 0;
    }
    60% {
      transform: scale3d(1.05, 1.05, 1);
    }
    100% {
      transform: scale3d(1, 1, 1);
      opacity: 1;
    }
  }
  /* hide animation */
  #main-menu ul.hide-animation {
    -webkit-animation: hide-animation 0.2s;
    animation: hide-animation 0.2s;
  }
  @-webkit-keyframes hide-animation {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale3d(0, 0, 1);
      opacity: 0;
    }
  }
  @keyframes hide-animation {
    0% {
      transform: scale3d(1, 1, 1);
      opacity: 1;
    }
    100% {
      transform: scale3d(0, 0, 1);
      opacity: 0;
    }
  }
}
